.page-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}
.main {
  background-color: #f5f5f7;
  font-size: 1.6rem;
}
.wrapper {
  flex: 1;
  max-width: 1200px;
  margin: 20px auto;
  min-height: 73vh;
}
h1,
h2,
h3,
h4,
h5,
h6,
a {
  text-transform: none;
}

.letterSpacing-ar {
  font-family: "Noto Kufi Arabic", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
}

.gradient__bg {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(250, 250, 250, 1) 51%,
    rgba(250, 250, 250, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(250, 250, 250, 1) 51%,
    rgba(250, 250, 250, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(250, 250, 250, 1) 51%,
    rgba(250, 250, 250, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FAFAFA', endColorstr='#FAFAFA', GradientType=0 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(250, 250, 250, 1) 51%,
    rgba(250, 250, 250, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(250, 250, 250, 1) 51%,
    rgba(250, 250, 250, 1) 100%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 1rem 6rem;
}

.section__margin {
  padding: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700x) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    padding: 4rem;
  }
}

@media screen and (max-width: 550x) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    padding: 4rem 2rem;
  }
}
