.jobmaroc__register-container,
.jobmaroc__register-container button,
.jobmaroc__register-form-body-agreement,
.justify-content-md-center,
.jobmaroc__register-container_input input {
  font-size: 1.6rem;
}

.linkedin-register-form {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: auto;
  margin: 0 auto;
  padding-top: 24px;
  border-radius: 0;
}

.linkedin-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.linkedin-button {
  width: 100%;
  background-color: #0a66c2;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 24px;
}

.error-message {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
}

.jobmaroc__register-form-body-agreement {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 1.2rem;
  line-height: 1.333;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin: 16px 0 16px 0;
  text-align: center;
}

.jobmarok-register-form_login {
  background-color: #fff;
  margin: 0 auto;
  padding: 16px 16px 24px;
  text-align: center;
  font: 14px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
}

.jobmarok-register-form_login a,
.jobmaroc__register-form-body-agreement a {
  text-decoration: none;
}
