/* src/components/JobHistoryPage.css */
.job-history-container {
  padding: 20px;
}

.job-table-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.job-history-container-table-header {
  display: flex;
  background-color: #f0f0f0;
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.job-history-container-table-row {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.job-history-container-table-cell {
  flex: 1;
}

/* src/components/JobHistoryPage.css */
/* ... (previous styles) */

.job-history-container-table-cell {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-history-container-table-cell button {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.job-history-container-table-cell button:hover {
  background-color: #cc0000;
}
