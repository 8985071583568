html {
  font-size: 62.5%;
}

body {
  margin: 0;
  --font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --gradient-text: linear-gradient(89.97deg, #736b62 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #736b62 -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-toggleMenu: #28328c;
  --color-footer: #f5f5f7;
  --footer-text-color: #fff;
  --footer-link-color: #fff;
  /* --color-footer : #031B34; */
  --color-blog: #042c54;
  --color-text: #8b8a88;
  /* --color-text: #81AFDD; */
  --color-subtext: #736b62;
  /* --color-subtext: #FF8A71; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
