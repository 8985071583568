.jobMarok__Profile-button_addJob,
.jobMarok__Profile-button_update,
.jobMarok__Profile-form_input {
  font-size: 1.6rem;
}

.jobMarok__profile,
.inputFontSize {
  font-size: 1.6rem;
}

.jobMarok__profile h2 {
  margin: 0px 0px 1.25rem;
  color: rgb(45, 45, 45);
  font-weight: 700;
  line-height: 1;
}

.jobMarok__profile .form-label {
  font-size: 1.6rem;
}

.ql-editor {
  background-color: #fff;
}
.jobMarok__profile form .mb-3 {
  font-size: 1.6rem;
}
