/* .job-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1em;
} */

/* 
.job-list img {
  width: 122px;
}

.job-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
}

.job-card p {
  font-size: 1.6rem;
}
.job-image {
  grid-row: 1 / 3;
  width: 100%;
  height: auto;
} */

/* .job-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.job-card {
  width: 300px;
  height: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 30px;
}

.job-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.job-card h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 20px;
}

.reduced-description {
  font-size: 16px;
  font-weight: 400;
  margin: 20px;
} */

.btn-job {
  background: none;
  border: none;
  border-color: brown;
}

.showSelectedJob {
  background-color: cornflowerblue;
  border-radius: 44px;
}

.unsetColorLink {
  color: inherit; /* This will use the default link color */
  text-decoration: none; /* Remove underlines and decorations */
}
