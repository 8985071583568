/* .form-control {
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #4285f4;
  color: white;
} */
/*     start here*/

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin: 20px auto;
  gap: 10px;
  margin-bottom: 16px;
}

.search-input {
  width: 80%;
  height: 40px;
  width: 256px;
  height: 28px;
  border: none;
  /* Default size/Body */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 22px;
  /* identical to box height, or 129% */

  letter-spacing: -0.408px;

  /* Label Colors/LC L Primary */

  color: #000000;
}

.search-container_jobTitle-icon,
.search-container_location-icon {
  width: 18px;
  height: 18px;
  color: rgba(60, 60, 67, 0.6);
}
.search-container_job,
.search-container_location {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 300px;
  height: 36px;
  /* background: rgba(250, 250, 250, 0.93); */
  /* System Materials/SM L Thick */
  /* background: rgba(250, 250, 250, 0.93); */
  border: 1px solid #dfe1e5;
  border-radius: 20px;
  box-shadow: 0 8px 6px -6px #202124;
  background-color: #ffffffff;
}
.search-button {
  width: 10%;
  height: 40px;
  border-radius: 20px;

  background-color: #4285f4;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  border: 1px solid #4285f4;
}

.search-button:focus,
textarea:focus {
  border: none;
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

@media (max-width: 900px) {
  .rtlMenu {
    right: -119px;
    min-width: 152px;
  }
  .jobmarok__navbar {
    margin: 0 20px 0 20px;
  }

  .search-container {
    width: 100%;
  }
  .search-container_job,
  .search-container_location {
    width: 40%;
  }
  .search-button {
    width: 14%;
  }

  .search-input {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 21px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .rtlMenu {
    right: -105px;
  }
  .jobmarok__navbar-menu_container {
    min-width: 164px;
  }

  .search-container {
    width: 97%;
    flex-direction: column;
    justify-content: unset;
  }

  .search-container_job,
  .search-container_location {
    width: 100%;
  }
  .search-input {
    width: 80%;
  }
  .search-button {
    width: 100%;
  }

  .search-input-container {
    flex-direction: column;
  }

  .search-icon,
  .clear-icon {
    margin: 10px auto;
  }
}

.search-button:hover {
  background-color: #357ae8;
}
