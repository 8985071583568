/* .job-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.job-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.job-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.job-card h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 20px;
}

.reduced-description {
  font-size: 16px;
  font-weight: 400;
  margin: 20px;
} */

/* .job-list img {
  width: 122px;
}

.job-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
}

.job-card p {
  font-size: 1.6rem;
}
.job-image {
  grid-row: 1 / 3;
  width: 100%;
  height: auto;
}
 */
/* .job-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.job-card {
  width: 300px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
} */

/* .job-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.job-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.job-card-image {
  grid-row: 1 / 3;
  width: 30%;
  height: auto;
}

.job-card-image {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center;
}

.job-card-info {
  padding: 10px;
  text-align: left;
}

.job-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.job-card-company {
  font-size: 14px;
  color: gray;
  margin-bottom: 5px;
}

.job-card-location {
  font-size: 14px;
  color: gray;
  margin-bottom: 5px;
} */

.selectedJob {
  background-color: #fbfbfd;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid blue;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.job-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.job-card-details {
  background-color: #fbfbfd;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.jobMarok__job-details {
  margin: 0px 0px 1.25rem;
  color: rgb(45, 45, 45);
  font-weight: 700;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial,
    "Liberation Sans", Roboto, Noto, sans-serif;
  /* font-size: 1.25rem; */
  line-height: 1;
}

.jobMarok__job-details_header {
  display: flex;
  justify-content: space-between;
}
#id {
  text-transform: none;
}
.job-card {
  background-color: #fbfbfd;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 1em;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.job-card-image {
  width: 100%;
  padding: 10px;
  background-size: cover;
  background-position: center;
  height: auto;
}

.job-card-info {
  padding: 10px;
  text-align: left;
}

.job-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.job-card-company {
  font-size: 14px;
  color: gray;
  margin-bottom: 5px;
}

.job-card-location {
  font-size: 14px;
  color: gray;
  margin-bottom: 5px;
}

/* Job Details */
.jobDetails {
  background-color: #fbfbfd;
  padding: 10px;
  margin-bottom: 20px;
}

.jobDetails h2 {
  margin-top: 0;
}

.jobDetails h3 {
  margin-bottom: 5px;
}

.jobDetails p {
  margin-top: 0;
}

.jobMarok__jobDetails-addToMyJob:hover {
  background-color: rgba(112, 181, 249, 0.15);
  -webkit-box-shadow: inset 0 0 0 1px #0a66c2;
  box-shadow: inset 0 0 0 1px #0a66c2;
}

.job-description-scroll {
  max-height: 470px;
  overflow-y: auto;
}
