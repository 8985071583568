.tabib__header {
    display: flex;
    flex-direction: row;
}

.tabib__header-content {
    flex: 1;
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem; 
    margin-top: 10rem;
}

.tabib__header-content h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -0.04em;
}
.tabib__header-content h5 {
    font-family: serif;
    font-weight: 800;
    font-size: 26px;
    /* line-height: 75px; */
    letter-spacing: -0.04em;
}

.tabib__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.tabib__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

/* .tabib__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
} */

.tabib__header-content__input button {
    /* flex: 0.6; */
    
    /* min-height: 50px; */
    font-family: var(--font-family);
    font-weight: 400;
    /* font-size: 20px; */
    line-height: 28px;
    padding: 0 1rem;
   
    cursor: pointer;
    outline: none;
    /* border-top-right-radius: 5px; */
    /* border-bottom-right-radius: 5px; */
}
.tabib__header-content__input .btn-play-video{
    background: #4D77FF;
    border: 2px solid #4D77FF;
    margin-right: 2rem;
    color: #fff;
}
.tabib__header-content__input .btn-reserve{
    background: #fcfcfd;
    border: 2px solid #f0dec0;
    color: #73695d;
}

/* .tabib__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
} */


.tabib__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabib__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .tabib__header {
        flex-direction: column;
    }

    .tabib__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .tabib__header-content h2 {
        font-size: 48px;
        line-height: 60px;
    }

    .tabib__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .tabib__header-content__people {
        flex-direction: column;
    }

    .tabib__header-content__people p {
        margin: 0;
    }

    .tabib__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .tabib__header-content h2 {
        font-size: 36px;
        line-height: 48px;
    }

    .tabib__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .tabib__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
} 