.about-screen {
  padding: 20px;
}

.about-section {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

/* Additional styles can be added as needed */
