.jobmaroc__login-container,
.jobmaroc__login-container button,
.jobmaroc__login-form-body-agreement,
.justify-content-md-center,
.jobmaroc__login-container_input input {
  font-size: 1.6rem;
}

.login-page {
  background-color: #ffffff;
  padding: 20px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: auto;
  margin: 0 auto;
  padding-top: 24px;
  border-radius: 0;
}

.jobmaroc__login-container_header__content__heading {
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  padding: 0 0 4px 0;
}

.jobmaroc__login-container_header__content__subheading {
  font-size: 2rem;
  line-height: 1.42857;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
}

.login-form_input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-button {
  width: 100%;
  background-color: #0a66c2;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 24px;
  font-size: 1.6rem;
}

.error-message {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
}

.jobmarok-login-form_register {
  text-align: center;
  margin: 0 auto;
  background-color: white;
}
.forgot-password {
  position: relative;
  left: -8px;
  margin-bottom: 19px;
  text-decoration: none;

  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0073b1;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: inherit;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  outline-width: 2px;
  padding: 0 8px;
}

.jobmarok-login-form_register a {
  text-decoration: none;
}

.jobmarok-login-form_register {
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  margin-top: 10px;
}
